@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* border: 1px solid #ff0000; */
  font-family: 'Poppins', sans-serif;
}

.top {
  position: relative;
  top: 70%;
}

/*=============== SCROLL BAR ===============*/
* {
  scrollbar-width: auto;
  scrollbar-color: #d7e0ff transparent;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d7e0ff;
  border-radius: 10px;
}

.app {
  visibility: hidden;
}

@media screen and (min-width: 1024px) {
  .react-toast > div {
    left: 256px !important;
  }
}
